<template>
  <InputContainer :label="label">
    <BaseTextInput
      placeholder="YYYY-MM-DD"
      pattern="\d{4}-\d{2}-\d{2}"
      :value="value"
      @input="$emit('input', $event)"
    >
      <template #append>
        <BaseIcon name="event" class="cal-icon" />
        <q-popup-proxy
          ref="dateMenu"
          transition-show="scale"
          transition-hide="scale"
        >
          <q-date
            today-btn
            color="purple-highlight"
            mask="YYYY-MM-DD"
            :model-value="value"
            @update:model-value="updateValue"
          />
        </q-popup-proxy>
      </template>
    </BaseTextInput>
  </InputContainer>
</template>

<script>
import InputContainer from './InputContainer'
import BaseTextInput from './BaseTextInput'
import BaseIcon from '@/components/BaseIcon'

export default {
  components: {
    InputContainer,
    BaseTextInput,
    BaseIcon
  },
  props: {
    label: String,
    placeholder: String,
    value: String
  },
  methods: {
    updateValue (newValue) {
      this.$emit('input', newValue)
      this.$refs.dateMenu.hide()
    }
  },
  emits: ['input']
}
</script>

<style scoped lang="scss">
.cal-icon {
  cursor: pointer;
}
</style>
