<template>
  <div>
    <ConsentBody v-if="!hasConsented" />
    <BaseCard v-else-if="resultsAvailable">
      <template #title>
        {{
          loading
            ?  ''
            : $t("searchResults.yourSearch", { site: resultsData.label, term: lastSearchSettings.searchTerm, })
        }}
        <div class="subtext">{{ $t("searchResults.maxResultsNote") }}</div>
      </template>
      <template #info-left>
        {{ lastSearchSettings.startDate }}
        -
        {{ lastSearchSettings.endDate }}
      </template>

      <template #content>
        <div v-if="!loading && resultsData.data.length === 0" >
          {{ $t("searchResults.noResults") }}
        </div>

        <q-card v-else v-for="website in resultsData.data" :key="website.label"
          :resultsData="website.data"
          class="card"
          >
          <div class="item-content">
            <q-avatar class="avatar" color="purple-highlight" text-color="white" icon="person" />
              <div v-if="website.author" class="author">
                {{ website.author }}
              </div>
              <div v-if="website.board" class="board" >
                <div v-if="resultsData.label === 'Telegram'" >
                  Channel: {{ website.board }}
                </div>
                <div v-else>
                  Board: {{ website.board }}
                </div>
              </div>
              <div v-if="website.text" class="text" >
                {{ website.text }}
              </div>
              <div class="responses">
                <div v-if="website.retweet_count" class="retweets">
                  <q-icon name="fa fa-retweet"/>
                  {{ website.retweet_count }}
                </div>
                <div v-if="website.favorite_count" class="favorite-count" >
                  <q-icon name="fa fa-heart"/>
                  {{ website.favorite_count }}
                </div>
                  <a v-if="website.link" class="website-link" :href=website.link target="_blank" >
                    {{ $t("searchResults.seeOriginal") }}
                  </a>
              </div>
          </div>
        </q-card>
      </template>
    </BaseCard>

    <BodyLoading :loading="loading" />
    <BodyError :error="error" />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { normaliseResults } from '@/helpers/normalise-results'
import BaseCard from '@/components/BaseCard'
import ConsentBody from '@/components/bodies/ConsentBody'
import BodyLoading from './BodyLoading'
import BodyError from './BodyError'
const { mapGetters } = createNamespacedHelpers('searchSettings')

export default {
  components: {
    BaseCard,
    BodyLoading,
    BodyError,
    ConsentBody
  },
  props: {
    lastSearchSettings: Object,
    results: Array,
    resultsAvailable: Boolean,
    loading: Boolean,
    error: Error
  },
  computed: {
    ...mapGetters(['hasConsented']),
    resultsData () {
      return normaliseResults(this.results)[0]
    }
  }
}
</script>

<style scoped lang="scss">
a {
  text-decoration: none;
  color: var(--color-black);
}

.subtext {
  color: var(--color-light-text);
  font-size: var(--fs-3);
}

.item-content {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 20px;
  margin-top: var(--spacing-1);
  font-size: var(--fs-3);
  font-weight: 500;
  color: var(--black);
  padding: 10px;

  .avatar {
    grid-row: 1 / 3;
    grid-column: 1;
  }

  .author {
    word-wrap: break-word;
    font-weight: bold;
  }

  .responses {
    grid-column: 2;
    display: grid;
    grid-template-columns: auto auto auto;
    justify-content: start;
    grid-gap: 20px;
  }

  .text {
    grid-column: 2;
  }

  .retweets {
    white-space: nowrap;
  }
}
</style>
