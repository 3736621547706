<template>
  <div>
    <slot
      :lastSearchSettings="lastSearchSettings"
      :results="results"
      :resultsAvailable="results.length > 0"
      :loading="queriesRunning > 0"
      :error="error"
    />
  </div>
</template>

<script>
import { cloneDeep, isEmpty } from 'lodash'
import { createNamespacedHelpers } from 'vuex'
import { queries as buildQueries } from '@/helpers/searchQuery'
const { GAB } = require('@/constants/sites')
const { SEARCH } = require('@/constants/tools')

const {
  mapState,
  mapGetters,
  mapMutations,
  mapActions
} = createNamespacedHelpers('searchSettings')

export default {
  props: {
    page: String // e.g. "hashtags"
  },
  data () {
    return {
      // TODO: replace this with a Promise.all and the `loading` prop with something like `!results && !error && !isEmpty(lastSearchSettings)`
      queriesRunning: 0,
      error: null,
    }
  },
  computed: {
    ...mapState(['settings', 'doSearch', 'history', 'toolResults', 'lastSearchSettings']),
    ...mapGetters(['websites']),
    results () {
      return this.toolResults[this.page]
    }
  },
  methods: {
    ...mapMutations(['setLastSearchSettings', 'setResults', 'resetResults', 'resetWebsitesActiveState', 'setWebsiteActive']),
    ...mapActions(['loadSettings']),
    search () {
      // TODO - move this into vuex action
      const queries = buildQueries(this.settings, this.page)
      this.queriesRunning = queries.length

      queries.forEach(query => {
        const startTime = Date.now()
        console.info('API', query.name, '-->')

        return fetch(query.url)
          .then(async response => {
            console.info('API', query.name, '<--', `${Math.floor((Date.now() - startTime) / 100) / 10}s`, query.url)
            if (response.status !== 200) { // 200 = OK
              const errJson = await response.json()
              throw errJson?.detail ?? errJson?.error ?? Error('Unknown API error')
            }

            const data = await response.json()

            this.setResults({
              tool: this.page,
              results: [...this.results, { ...query, data }]
            })

            this.queriesRunning = this.queriesRunning - 1
            if (this.queriesRunning) return

            this.error = null

            this.setLastSearchSettings(cloneDeep(this.settings))

            this.updateUrl()
          })
          .catch((err) => {
            this.queriesRunning = 0
            this.error = err
            console.error(err)
          })
      })
    },
    updateUrl () {
      const query = this.lastSearchSettings

      // haven't made a search with this tool yet
      if (isEmpty(query)) return

      // the default url-ification of the websites object is weird so we'll
      // make it nicer
      const websites = query.websites
        .filter((website) => website.active)
        .map((website) => website.name)
        .join(',')

      const cleanQuery = { ...query, websites }

      // TODO make query handling more consitent across app
      if (cleanQuery.changepoint !== undefined) {
        cleanQuery.changepoint = Boolean(cleanQuery.changepoint)
      }

      const currentQuery = new URLSearchParams(this.$route.query)
      currentQuery.sort()
      const newQuery = new URLSearchParams(cleanQuery)
      newQuery.sort()

      if (currentQuery.toString() === newQuery.toString()) return

      this.$router.replace({ query: cleanQuery })
    }
  },
  watch: {
    // when the user clicks Search, this variable is incremented, so we watch
    // it to find out when the user wants to search. a weird kind of event bus
    'doSearch.count': function () {
      if (this.doSearch.page === this.page) {
        // when making a new search we need to throw out old settings on all tools
        this.resetResults()
        this.search()
      }
    },
    $route (newRoute) {
      // if we searched on another tool, and we navigate to this tool but the same search hasn't been done here yet.
      if (newRoute.name === this.page && !isEmpty(newRoute.query) && isEmpty(this.results)) {
        if (this.page === SEARCH) {
          // the search tool only allows searching 1 website at a time so in case multiple are selected we need to reduce that
          // 1. find the website to keep
          const oneWebsiteIndex = this.websites.findIndex((website) => website.active)
          // 2. disable all websites
          this.resetWebsitesActiveState()
          // 3. enable the one we picked
          this.setWebsiteActive({ i: oneWebsiteIndex, val: true })
        }
        this.search()
      }
    }
  },
  created () {
    // if there are query params in the url when the page is loaded,
    // then grab those and perform a search with them

    const params = this.$route.query

    if (!isEmpty(params)) {
      // TODO move this logic into vuex
      // consider making changes to route an action?
      // sitll need something like this to load params from a URL into state tho
      const paramWebsites = (params.websites || GAB).split(',')

      // turn it back to the actual format that we use
      const fixedWebsites = this.websites.map((website) => ({
        ...website,
        active: paramWebsites.includes(website.name)
      }))

      this.loadSettings({ ...params, websites: fixedWebsites })
    }

    this.search()
  }
}
</script>

<style scoped lang="scss"></style>
