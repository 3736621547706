import { createRouter, createWebHistory } from 'vue-router'
import TimelineView from '@/views/TimelineView'
import HashtagsView from '@/views/HashtagsView'
import LinksView from '@/views/LinksView'
import ActivityView from '@/views/ActivityView'
import SearchView from '@/views/SearchView'

import SettingsTimeline from '@/components/sidebar/SettingsTimeline'
import SettingsHashtags from '@/components/sidebar/SettingsHashtags'
import SettingsLinks from '@/components/sidebar/SettingsLinks'
import SettingsActivity from '@/components/sidebar/SettingsActivity'
import SettingsSearch from '@/components/sidebar/SettingsSearch'

const { TIMELINE, HASHTAGS, LINKS, ACTIVITY, SEARCH } = require('@/constants/tools')

const routes = [
  {
    path: '/',
    redirect: '/timeline'
  },
  {
    path: '/timeline',
    name: TIMELINE,
    components: {
      page: TimelineView,
      settings: SettingsTimeline
    }
  },
  {
    path: '/hashtags',
    name: HASHTAGS,
    components: {
      page: HashtagsView,
      settings: SettingsHashtags
    }
  },
  {
    path: '/links',
    name: LINKS,
    components: {
      page: LinksView,
      settings: SettingsLinks
    }
  },
  {
    path: '/activity',
    name: ACTIVITY,
    components: {
      page: ActivityView,
      settings: SettingsActivity
    }
  },
  {
    path: '/search',
    name: SEARCH,
    components: {
      page: SearchView,
      settings: SettingsSearch
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router